.side_bar_container{
    display: block;
    position:absolute;
    left:0px;
    top:0px;
    width:20rem;
    height:100%;
    background: white;
    box-shadow: 0px 0px 10px #888888;
    z-index: 20000;
    transition-duration: 0.5s;
    transform: translate3d(-30rem,0,0);
    .but_form_exit{
        display:block;
        position:absolute;
        right:0px;
        top:0px;
        border:none;
        background-color: transparent;
        padding: 0.6rem;
    }
    .but_change_car{
        margin-top:3rem;
    }

    .side_bar_button{
        border:none;
        background: transparent;
        width:80%;
        display: flex;
        align-items: center;
        margin-left:10%;
        .img_container{
            width:2rem;
            text-align: center;   
        }
        span{
            text-align: left;
            margin-left: 1rem;
        }
    }
    .seperator{
        width:80%;
        height:1px;
        margin:10%;

    }

}
.side_bar_container.open{
    transform: translate3d(0,0,0);
}