
.form_input_container{
    display: flex;
    
    flex-direction: column;
    align-items: center;
    
    .but_form_exit{
        display:block;
        position:absolute;
        right:0px;
        top:0px;
        border:none;
        background-color: transparent;
        padding: 0.6rem;
    }
    .form_input_date_controls{
        display: flex;
        justify-content: center;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        font-size:1.8rem;
        button{
            border:none;
            background-color: transparent;
        }
    }
    .form_input_modal_close_month{
        display: flex;
        flex-direction: column;
        align-items: center;
        .input_total_car_distance{
            width:15rem;
            text-align: center;
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        width:100%;
        .overlimit_container{
            width:100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
            .overlimit_excess_label{
                margin-bottom: 0;
            }
            .overlimit_excess{
                font-size:1.5rem;
            }
            img {width: 2rem;margin-left:-2rem ;margin-top: 0.2rem;}
        }
    }
    .form_input_footer{
        width:25rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .form_input_button{
            border-radius: 5px;
            border:none;
            font-size:0.8rem;
            color:white;
            width: 10rem;
            height:2rem;
          
        
        }
    }
}
.form_but_input_check{
    border:none;
    background-color: transparent;
    padding: 0;
}

.form_input_modal {
   
    width:25rem;
}
.form_input_group{
    margin-bottom: 2rem;
}

.form_input_group_checkboxes{
    display: flex;
    justify-content:space-between;
    align-items: center;
    button {
        display: flex;
        align-items: center;
        .text-left {
            text-align: left;
        }
    }
}

@for $i from 1 through 6 {
    $zIndexBackdrop:  #{1000 + (5 * $i)};
    $zIndexContent:  #{1000 + (5 * $i) + 2};
    .modal-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
      z-index: $zIndexContent;
    }
  }