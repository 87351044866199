
.remind_password_screen {

    display: flex;
    flex-direction: column;
    align-items: center;
    position:absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    width:100%;
    height:100%;
    justify-content: center;
    .login_logo{
        width:4.6rem;
        margin-bottom: 1rem;
    }
    .app_title{
        font-size: 1.6rem;
        margin-bottom:1.5rem;
    }
    .app_subtitle{
        font-size: 1.2rem;
        margin-bottom:1.5rem;
        max-width: 40rem;
        text-align: center;
    }
    
    .input_email_container{
        display: flex;
        flex-direction: row;
        width:20rem;
        align-items: center;
        .login_input_label{
            font-size: 0.7rem;
            width:5rem;
        }
        input{
            flex:1;
            background-color: transparent;
            border:none;
            text-align: right;
            padding-right:30px;
            margin-right:-30px;
            padding-left: 100px;
    margin-left: -100px;
            
        }
        input:focus{
            border:none;
            outline: none;
        }
        
    }
    .login_error{
        margin-bottom: 1rem;
        font-size:1rem;
        line-height: 1rem;
        height:1rem;
    }
    .seperator{
        width:20rem;
        height:1px;
        margin-bottom: 2rem;

    }
    .login_but_submit{
        color:white;
        font-size: 1.2rem;
        padding:0.7rem 0.8rem;
        border-radius: 0.4rem;
        border:none;
        margin-bottom: 2rem;

    }
    .login_but_back {
        cursor: pointer;
    } 
}