@import  './css/colors';

.form_type_options{
    .but_form_exit{
        display:block;
        position:absolute;
        right:0px;
        top:0px;
        border:none;
        background-color: transparent;
        padding: 10px;
    }

    .type_options_header{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        button{
            border:none;
            background-color: transparent;
        }
    }
}


.type_options_container{
    display: flex;
    justify-content: space-around;

    .type_option{
        width:164px;
        height:182px;
        border-radius: 6px;
        border:none;
        background-color: $colorLightGray;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .option_icon_container{
            flex:1;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .option_footer{
            color:white;
            font-size:14px;
            line-height: 16px;
            padding: 5px 0px;
            border-radius: 6px;
        }
    }

    .type_option_seperator{
        width: 2px;
        height:182px;
        background-color: $colorLightGray;
    }
}