$mainColor:#00f;
$colorBlue:#0073B7;
$colorGreen:#008328;
$colorOrange:#FF7F00;
$colorRed:#D12B2B;
$colorBlueLight:#E0F2FF;
$colorLightGray:#EDEDED;
$colorGray:#848484;
$colorBlack:#000;

.green{
    color:$colorGreen;
}
.orange{
    color:$colorOrange;
}
.blue{
    color:$colorBlue;
}
.red{
    color:$colorRed;
}
.gray{
    color:$colorGray;
}
.black{
    color:$colorBlack;
}

.bg_green{
    background-color:$colorGreen;
}
.bg_orange{
    background-color:$colorOrange;
}
.bg_blue{
    background-color:$colorBlue;
}
.bg_red{
    background-color:$colorRed;
}
.bg_gray{
    background-color:$colorGray;
}
.bg_lightgray{
    background-color:$colorLightGray;
}

.btn-danger{
    background-color:$colorRed;
}

.btn-primary{
    background-color:$colorBlue;
}