@import  './css/colors';

.fc-toolbar-title{
    display: inline;
    line-height: 1.5;
    
}
.fc-prev-button {
    background:transparent!important;
    border: none!important;
}
.fc-next-button {
    background:transparent!important;
    border: none!important;
}
.fc-button-primary {
    
    span{
        color:$colorBlue;
    }
    
}
.calendar_container {
    .form_input_date_controls{
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size:1.8rem;
        button{
            img {pointer-events: none;}
            border:none;
            background-color: transparent;
            padding: 0 0.8rem;
        }
    }
}

.event_container.red_border{
    border: 2px dashed red;
}
.event_container {
    cursor: pointer;
    border-radius: 4px;
    background-color: $colorGreen;

    color: black;
    .event_content.event_content_row
    {
        display:flex;
        flex-direction: column;
        .event_content_top_row {
            display: flex;
            flex-direction: row;
        }
        .destination{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.7rem;
        }
    }

    .event_content{
        display:flex;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left:10px;
        background-color: $colorLightGray;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        padding-left: 10px;
        padding-right:10px;
        
        .event_values{
            flex:1;
            display:flex;
            justify-content:space-around;
        }
    }
}
.event_expense_container {
    background-color: $colorGreen;
    .event_expense_content{
        background-color: $colorLightGray;
    }
}
.event_route_container {
    background-color: $colorBlue;
    .event_route_content{
        background-color: $colorBlueLight;
    }
}


