@import 'bootstrap/dist/css/bootstrap.min.css';
@import './css/colors.scss';
body{
  font-size:16px;
  font-family: Arial, Helvetica, sans-serif;
}
.calendar_container{
  padding: 1rem;
}
a{
  color: black;
  text-decoration: none;
}


.loading_screen{
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position:absolute;
  left:0px;
  top:0px;
  right:0px;
  bottom:0px;
  width:100%;
  height:100%;
  justify-content: center;
}
.submit_with_replacement_car_message{
  a {
    color: $colorBlue;
  }
}