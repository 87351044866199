@import  './css/colors';

.main_header_container{
    .main_header{
        .vehicle_info{
            font-size:0.7rem;
        }
        .plate_number {
            font-size:1.3rem;
            margin-right:1rem;
        }

        margin-top:10px;
        display: flex;
        .but_hamburger{
            background: none;
            border: none;
            margin:0rem 0.5rem;
        }
        .but_close_month{

            display: flex;
            color: white;
            border:none;
            border-radius: 0.4rem;
            align-items: center;
            font-size: 1.5rem;
            span {
                margin: 0rem 0.8rem;
            }
            img{
                margin-left: 1.2rem;
                margin-right:0.8rem;
             
            }
            .seperator{
                height:100%;
                width:1px;
                background: white;
            }
            margin:0.5rem 1rem;
        }
        .but_close_month.two_row_font_size{
            font-size:1rem;


        }
        .main_header_content{
            .main_header_content_left{
                .main_header_content_left_item{
                    .img_contair{
                        position: relative;
                        top:-0.2rem;
                    }
                }
            }
            flex:1;
            display: flex;
            justify-content: space-between;
            .summary{
                display: flex;
                img{
                    margin-right:4px;
                }
                .summary_col{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right:20px;
                    font-size: 1.15rem;
                    .summary_title{
                        color: #848484;
                        font-size:0.6rem;
                    }
                }
            }
        }
    }
    .header_seperator{
        width: 100%;
        height: 3px;
        margin-top: 4px;
        background-color: $colorBlue;
    }
}
